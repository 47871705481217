/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import { pages } from "./pages";
import { translate as t } from "./i18n";

export const PLACEHOLDER_ANIMATION_TYPE = "glow";

export const EMAIL_REGEX = "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$";

// Password Conditions

/**
 * Special characters regex
 *
 * Uses unicode ranges to cover all special characters supported
 * instead of individually declaring them.
 * The ranges correspond to the following characters: [space]!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~
 *
 * Based on the list in https://owasp.org/www-community/password-special-characters
 *
 */
export const SPECIAL_CHARACTERS =
  "\u0020\u0021\u0022\u0023\u0024\u0025\u0026\u0027\u0028\u0029\u002A\u002B\u002C\u002D\u002E\u002F\u003A\u003B\u003C\u003D\u003E\u003F\u0040\u005B\u005C\u005D\u005E\u005F\u0060\u007B\u007C\u007D\u007E";
export const PASSWORD_MIN_LENGTH = 8;

/**
 * Escapes special regex characters in a string
 * Use this to make any string safe for inclusion in RegExp
 */
export const escapeRegExp = (string: string) =>
  string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

/**
 * Password Regex for validation
 * Based on the complex password regex on
 * https://owasp.org/www-community/OWASP_Validation_Regex_Repository
 *
 * Conditions:
 * * more than 8 characters
 * * At least 3 of the following 4 conditions:
 *   * uppercase letters
 *   * lowercase letters
 *   * numbers
 *   * special characters
 */
export const PASSWORD_REGEX = `^(?=(?:[^A-Z]*[A-Z]){0,1}(?:[^a-z]*[a-z]){1}(?:[^\\d]*\\d){0,1}|(?:[^A-Z]*[A-Z]){1}(?:[^a-z]*[a-z]){0,1}(?:[^\\d]*\\d){1}|(?:[^A-Z]*[A-Z]){1}(?:[^a-z]*[a-z]){1}(?:[^\\d]*\\d){0,1})[A-Za-z0-9${SPECIAL_CHARACTERS.replace(
  /[-\/\\^$*+?.()|[\]{}]/g,
  "\\$&"
)}]{${PASSWORD_MIN_LENGTH},}$`;

const passwordRegex = new RegExp(PASSWORD_REGEX);
const specialCharsRegex = new RegExp(`[${SPECIAL_CHARACTERS}]`);
export const isTestPasswordValid = (string) => {
  const conditionsMet = [
    /\d/.test(string),
    /[A-Z]/.test(string),
    /[a-z]/.test(string),
    specialCharsRegex.test(string),
  ].filter(Boolean).length;
  return (
    passwordRegex.test(string) &&
    conditionsMet >= 3 &&
    string.length >= PASSWORD_MIN_LENGTH
  );
};

export const DEFAULT_SEARCH_DEBOUNCE_MS = 500;
export const DEFAULT_ACTION_DEBOUNCE_MS = 2000;

// Contact information
export const NO_REPLY_EMAIL = "no-reply@fastlanefi.com";
export const NO_REPLY_LAKEVIEW_EMAIL =
  "no-reply@lakeviewloanservicing.fastlanefi.com";
export const CONTACT_EMAIL = "support@fastlanefi.com";
export const LAKEVIEW_MORTGAGE_PHONE = "1-855-294-8564";
export const GET_STARTED_HOME_EQUITY_PHONE = "844-970-0610";
export const GET_STARTED_CASHOUT_PHONE = "844-518-2803";
export const HELOAN_NEXT_STEPS_PHONE = "855-713-2644";
export const DIGITAL_ACCOUNT_PHONE = "855-294-8654";
export enum PHONE_NUMBERS {
  TWENTY_EIGHT_ZERO_THREE = "(844) 518-2803",
}

// T&C settings
export const TERMS_CONDITIONS_VERSION = "1.1.0";
export const AFBA_VERSION = "1.0.0";
const TERMS_LAST_UPDATED = "September 2024";
export const TERMS_CONDITIONS_LAST_UPDATED = t(
  "terms_conditions.dashboard.revised_on",
  {
    lastUpdated: TERMS_LAST_UPDATED,
  }
);
const DIGITAL_TERMS_LAST_UPDATED = "September 2024";
export const DIGITAL_TERMS_CONDITIONS_LAST_UPDATED = t(
  "terms_conditions.digital.revised_on",
  {
    lastUpdated: DIGITAL_TERMS_LAST_UPDATED,
  }
);

export const TERMS_CONDITIONS_DIGITAL_HOME_EQUITY_LOAN_LAST_UPDATED = t(
  "terms_conditions.heloan.revised_on",
  {
    lastUpdated: DIGITAL_TERMS_LAST_UPDATED,
  }
);

export const DIGITAL_ACCOUNT_VERSIONS = {
  TERMS_AND_CONDITIONS: "1.0.0",
  USE_ELECTRONIC_RECORDS: "1.0.0",
  TELEPHONE_COMMUNICATIONS: "1.0.0",
};

// PDP Apply now link
export const APPLY_NOW_BASE_URL = `${
  process.env.REACT_APP_LAKEVIEW_APPLY_NOW_BASE_URL ||
  "https://lv-cd-uat.tavant.com/"
}`;

// PDP Get Quote link
export const GET_QUOTE_LINK = "https://www.lakeviewinsurancesolutions.com/cqt";

/** SSN Validation conditions */
export const NUMBER_REGEX = "\\d*";
export const SSN_LENGTH = 4;

/** Loan number validation conditions */
export const LOAN_NUMBER_LENGTH = 16;

// ZIP Code validation condition
export const ZIP_CODE_LENGTH = 5;

export interface HeaderProductMenuItem {
  translationKey: string;
  path: string;
  mixpanelButtonName: string;
}

/**
 * List of products displayed in the header menus
 */

export const HOME_EQUITY_LOAN_TRANSLATION_KEY = "home_equity_loan";
export const PRODUCT_LIST = (
  includeHomeEquityLoan: boolean = false
): HeaderProductMenuItem[] => [
  // Cash Out Refinance
  {
    translationKey: "cash_out_refinance",
    path: pages.products.cashOutRefinance,
    mixpanelButtonName: "go-to-cashout-pdp",
  },
  // Home equity loan
  {
    translationKey: HOME_EQUITY_LOAN_TRANSLATION_KEY,
    path: includeHomeEquityLoan
      ? pages.products.homeEquityLoan
      : pages.products.cashOutRefinance,
    mixpanelButtonName: "go-to-heloan-pdp",
  },
  // Get a Mortgage
  {
    translationKey: "get_mortgage",
    path: pages.products.getAMortgage,
    mixpanelButtonName: "go-to-mortgage-pdp",
  },
  // Rate Term Refinance
  {
    translationKey: "rate_term_refinance",
    path: pages.products.rateTermRefinance,
    mixpanelButtonName: "go-to-rtr-pdp",
  },
];

/**
 * Metadata page's title
 */
export const PAGE_TITLES = {
  fastlane: t("meta.fastlane"),
  lakeviewCobranding: t("meta.lakeview_cobranding"),
  index: t("meta.page_titles.index"),
  home: t("meta.page_titles.home"),
  profile: t("meta.page_titles.profile"),
  signup: t("meta.page_titles.sign_up"),
  termsConditions: t("meta.page_titles.terms_conditions"),
  needToVerifyEmail: t("meta.page_titles.need_to_verify_email"),
  frequentlyAskedQuestions: t("meta.page_titles.faq"),
  cashOutRefinance: t("meta.page_titles.cash_out_refinance"),
  homeEquityLoan: t("meta.page_titles.home_equity_loan"),
  getAMortgage: t("meta.page_titles.get_a_mortgage"),
  rateTermRefinance: t("meta.page_titles.rate_term_refinance"),
  resourceCenter: t("meta.page_titles.resource_center.index"),
  adaAccessibility: t("meta.page_titles.ada_accessibility"),
  error: t("meta.page_titles.error"),
  404: t("meta.page_titles.not_found"),
  thingsSavvyHomeownersAreConsidering: t(
    "meta.page_titles.resource_center.things_savvy_homeowners_considering_now"
  ),
  gapsInHomeownerInsuranceCoverage: t(
    "meta.page_titles.resource_center.gaps_in_homeowner_insurance_coverage"
  ),
  energyEfficientHomeImprovementProjects: t(
    "meta.page_titles.resource_center.energy_efficient_home_improvement_projects"
  ),
  feelingWeighedDownByConsumerDebt: t(
    "meta.page_titles.resource_center.feeling_weighed_down_by_consumer_debt"
  ),
  unlockNewFinancialOpportunitiesWithHeloan: t(
    "meta.page_titles.resource_center.unlock_new_financial_opportunities_with_heloan"
  ),
  gettingCashOutRefinance: t(
    "meta.page_titles.resource_center.getting_cash_out_refinance"
  ),
  assumableMortgages: t("meta.page_titles.resource_center.assumable_mortgages"),
  hiringInsuredLicensedBondedContractors: t(
    "meta.page_titles.resource_center.hiring_insured_licensed_bonded_contractors"
  ),
  tipsToPrepareYourHomeForSale: t(
    "meta.page_titles.resource_center.tips_to_prepare_your_home_for_sale"
  ),
  signsOfRoofReplacement: t(
    "meta.page_titles.resource_center.signs_of_roof_replacement"
  ),
  topHomebuyerConsiderationsChallenges2024: t(
    "meta.page_titles.resource_center.top_homebuyer_considerations_challenges_2024"
  ),
  monitorHomeEquity: t("meta.page_titles.resource_center.monitor_home_equity"),
  considerationsBeforeInstallingPool: t(
    "meta.page_titles.resource_center.considerations_before_installing_pool"
  ),
  homeInsuranceDiscounts: t(
    "meta.page_titles.resource_center.home_insurance_discounts"
  ),
  landscapingTipsForWaterConservation: t(
    "meta.page_titles.resource_center.landscaping_tips_for_water_conservation"
  ),
  howToProtectYourHomeFromExtremeWeather: t(
    "meta.page_titles.resource_center.how_to_protect_your_home_from_extreme_weather"
  ),
  howYourCreditScoreImpactsMortgageLoanTerms: t(
    "meta.page_titles.resource_center.how_your_credit_score_impacts_mortgage_loan_terms"
  ),
};
export const PAGE_META_DESCRIPTION = {
  default: t("meta.description.default"),
  index: t("meta.description.index"),
  cashOutRefinance: t("meta.description.cash_out_refinance"),
  getAMortgage: t("meta.description.get_a_mortgage"),
  rateTermRefinance: t("meta.description.rate_term_refinance"),
  frequentlyAskedQuestions: t("meta.description.faq"),
  resourceCenter: t("meta.description.resource_center.index"),
  termsConditions: t("meta.description.terms_conditions"),
  adaAccessibility: t("meta.description.ada_accessibility"),
  thingsSavvyHomeownersAreConsidering: t(
    "meta.description.resource_center.things_savvy_homeowners_considering_now"
  ),
  gapsInHomeownerInsuranceCoverage: t(
    "meta.description.resource_center.gaps_in_homeowner_insurance_coverage"
  ),
  energyEfficientHomeImprovementProjects: t(
    "meta.description.resource_center.energy_efficient_home_improvement_projects"
  ),
  feelingWeighedDownByConsumerDebt: t(
    "meta.description.resource_center.feeling_weighed_down_by_consumer_debt"
  ),
  unlockNewFinancialOpportunitiesWithHeloan: t(
    "meta.description.resource_center.unlock_new_financial_opportunities_with_heloan"
  ),
  gettingCashOutRefinance: t(
    "meta.description.resource_center.getting_cash_out_refinance"
  ),
  assumableMortgages: t("meta.description.resource_center.assumable_mortgages"),
  hiringInsuredLicensedBondedContractors: t(
    "meta.description.resource_center.hiring_insured_licensed_bonded_contractors"
  ),
  tipsToPrepareYourHomeForSale: t(
    "meta.description.resource_center.tips_to_prepare_your_home_for_sale"
  ),
  signsOfRoofReplacement: t(
    "meta.description.resource_center.signs_of_roof_replacement"
  ),
  topHomebuyerConsiderationsChallenges2024: t(
    "meta.description.resource_center.top_homebuyer_considerations_challenges_2024"
  ),
  monitorHomeEquity: t("meta.description.resource_center.monitor_home_equity"),
  considerationsBeforeInstallingPool: t(
    "meta.description.resource_center.considerations_before_installing_pool"
  ),
  homeEquityLoan: t("meta.description.home_equity_loan"),
  homeInsuranceDiscounts: t(
    "meta.description.resource_center.home_insurance_discounts"
  ),
  landscapingTipsForWaterConservation: t(
    "meta.description.resource_center.landscaping_tips_for_water_conservation"
  ),
  howToProtectYourHomeFromExtremeWeather: t(
    "meta.description.resource_center.how_to_protect_your_home_from_extreme_weather"
  ),
  howYourCreditScoreImpactsMortgageLoanTerms: t(
    "meta.description.resource_center.how_your_credit_score_impacts_mortgage_loan_terms"
  ),
};

/**
 * Metadata page's description
 */

/**
 * PDP get-a-mortgage Step's link
 */
export const LAKEVIEW_HOME_STORY_LINK = "https://lakeview.homestory.co";

/**
 * ADA Accessibility
 */
const ADA_ACCESSIBILITY_LAST_UPDATED_DATE = "11/16/2023";
export const ADA_ACCESSIBILITY_LAST_UPDATED = t(
  "adaAccessibility.body.last_updated",
  {
    lastUpdated: ADA_ACCESSIBILITY_LAST_UPDATED_DATE,
    interpolation: { escapeValue: false },
  }
);

const ARDLEY_HELOAN_LINK =
  "https://api.offers.lakeview.com/v1/l/1635e092f5dc939ebfa547a1ad79fb16/r";

/**
 * ***************************************
 * Tavant Constants
 * ***************************************
 */

/**
 * FlId Product values
 * More information https://fastlanefi.atlassian.net/wiki/spaces/CF/pages/91848799/LpbF+Tracking+Codes#Tag-Values-(FLID)--
 */
export const LAKEVIEW_PRODUCT = {
  CASHOUT_REFINANCE: "cor",
  CASHOUT_HELOAN: "coh",
  RATE_TERM_REFINANCE: "rtr",
  PURCHASE_MORTGAGE: "pm",
  TAVANT_DIGITAL_HELOAN: "hln",
} as const;

/**
 * FlId page/module values
 * More information https://fastlanefi.atlassian.net/wiki/spaces/CF/pages/91848799/LpbF+Tracking+Codes#Tag-Values-(FLID)--
 */
export const LAKEVIEW_MODULE = {
  WTQ: "wtq",
  PRL: "prl",
  PEO: "peo",
  PDP: "pdp",
  EML: "eml",
  NXTSTP: "nxtstp",
  FORM: "form",
} as const;

/**
 * FlId subchannel values
 * More information https://fastlanefi.atlassian.net/wiki/spaces/CF/pages/91848799/LpbF+Tracking+Codes#Tag-Values-(FLID)--
 */
export const LAKEVIEW_SUBCHANNEL = {
  FLD: "fld",
  FLM: "flm",
  LNQT: "lnqt",
  LNPREQ: "lnpreq",
  LNAPP: "lnapp",
} as const;

export const LAKEVIEW_LINK_TYPE = {
  OFFERS: "offers",
  APPLY_NOW: "apply_now",
  CASH_OUT_APPLY: "cash_out_apply",
  BOLT_INSURANCE_LHIS: "bolt_insurance_lhis",
  TAVANT_DIGITAL_HELOAN: "tavant_digital_heloan",
  LOG_IN_TO_MY_SERVICER: "log_in_to_my_servicer",
  LAKEVIEW_HELOAN: "lakeview_heloan",
} as const;

export const LAKEVIEW_PRODUCT_ID = {
  PURCHASE: "Purchase",
  REFINANCE: "Refinance",
} as const;

export enum FLID_MAP {
  OFFER_FHA_SL_POST = "119",
  OFFER_FHA_SL_POST_MODAL = "126",

  OFFER_VA_IRRRL = "120",
  OFFER_VA_IRRRL_MODAL = "128",

  OFFER_CONV_RT = "121",
  OFFER_CONV_RT_MODAL = "127",

  OFFER_CAO_ONLY = "122",
  OFFER_CAO_ONLY_MODAL = "122",

  OFFER_DEBT_CONSOLIDATE = "123",
  OFFER_DEBT_CONSOLIDATE_MODAL = "123",

  OFFER_DEBT_PMT_SAVINGS = "124",
  OFFER_DEBT_PMT_SAVINGS_MODAL = "124",

  OFFER_CAO_HELOAN = "125",
  OFFER_CAO_HELOAN_MODAL = "129",

  PAY_OFF_MY_DEBT_GOAL = "132",
  HELOAN_PDP = "133",
}

export const EXPERIMENTS_FLID = {
  TAVANT_DIGITAL_HELOAN_EQUITY_PLANNER: "111",
  TAVANT_DIGITAL_HELOAN_EXPLORE_PRODUCTS: "112",
  TAVANT_DIGITAL_HELOAN_PDP: "113",
  TAVANT_DIGITAL_HELOAN_MODULE_CASH_OUT_PDP: "114",
  TAVANT_DIGITAL_HELOAN_PERSONALIZED_OFFERS: "116",
} as const;

export type LakeviewLinkType =
  (typeof LAKEVIEW_LINK_TYPE)[keyof typeof LAKEVIEW_LINK_TYPE];

export const LAKEVIEW_URLS: Record<LakeviewLinkType, string> = {
  offers: ARDLEY_HELOAN_LINK,
  apply_now: process.env.REACT_APP_LAKEVIEW_APPLY_NOW_BASE_URL
    ? `${process.env.REACT_APP_LAKEVIEW_APPLY_NOW_BASE_URL}loan-request`
    : "https://lv-cd-qa.tavant.com/loan-request",
  bolt_insurance_lhis:
    process.env.REACT_APP_BOLT_INSURANCE_LHIS_URL ??
    "https://apply.lhis.fastlanefi.com/application",
  cash_out_apply: process.env.REACT_APP_LAKEVIEW_APPLY_NOW_BASE_URL
    ? process.env.REACT_APP_LAKEVIEW_APPLY_NOW_BASE_URL
    : "https://apply.lakeview.com/",
  tavant_digital_heloan:
    process.env.REACT_APP_TAVANT_DIGITAL_HELOAN_URL ??
    "https://lv-fl-dev.tavant.com/",
  log_in_to_my_servicer: "https://lakeview.com/log-in-to-my-servicer/",
  lakeview_heloan: "https://lakeview.com/heloan",
};

export type LakeviewModuleType =
  (typeof LAKEVIEW_MODULE)[keyof typeof LAKEVIEW_MODULE];
export type LakeviewProductType =
  (typeof LAKEVIEW_PRODUCT)[keyof typeof LAKEVIEW_PRODUCT];
export type LakeviewProductId =
  (typeof LAKEVIEW_PRODUCT_ID)[keyof typeof LAKEVIEW_PRODUCT_ID];
export type LakeviewSubchannelType =
  (typeof LAKEVIEW_SUBCHANNEL)[keyof typeof LAKEVIEW_SUBCHANNEL];

export const LAKEVIEW_APP_TYPE = {
  LOAN_REQUEST: "loan-request",
  LOGIN: "login",
} as const;

export const LHIS_LANDING_PAGE =
  "https://www.lakeviewinsurancesolutions.com/cqt/";

/**
 * Cash Out Loan Quote
 */
export const CASH_OUT_BASE_URL: string = pages.cashOutRefinanceLoanQuote;
export const INPUT_CURRENCY_PLACEHOLDER: string = "$ 0,000.00";
export const INPUT_CURRENCY_REGEX_PATTERN: string =
  "^\\$\\d{1,3}(,\\d{3})*(\\.\\d+)?$";
export const CASH_OUT_NO_REFINANCE_STATES = ["NY", "HI"];
export const CASH_OUT_START_APPLICATION_ONLINE_URL =
  "https://apply.lakeview.com";
export const CONSUMER_ACCESS_LINK = "https://nmlsconsumeraccess.org";
export const CASH_OUT_FLOW_MIXPANEL_MODULE = "cash-out-refinance-loan-quote";
export const CASH_OUT_PHONE_NUMBER = "(844) 974-4609";

export enum CASH_OUT_SESSION_STORAGE_KEYS {
  SIGN_IN = "cash_out_sign_in",
  LOAN_QUOTE_STATE = "cash_out_loan_quote_state",
  LOAN_FLOW_STATE = "cash_out_loan_flow_state",
  GUEST_USER_FLOW_STARTED = "guest_user_cash_out_flow_started",
}

export enum PROPERTY_TYPE {
  SINGLE_FAMILY = "SINGLE_FAMILY",
  CONDO = "CONDO",
  FAMILY_2_4 = "FAMILY_2_4",
  OTHER = "OTHER",
}

export enum OCCUPANCY_TYPE {
  OWNER_OCCUPIED = "OWNER_OCCUPIED",
  SECOND_HOME = "SECOND_HOME",
  INVESTMENT = "INVESTMENT",
}

export enum CASH_OUT_STEPS {
  GET_STARTED = "get_started",
  IS_MORTGAGE_BY_LAKEVIEW = "is_mortgage_by_lakeview",
  SIGN_IN = "sign_in",
  PAY_OFF_DEBT = "pay_off_debt",
  DEBT_AMOUNT = "debt_amount",
  MONTHLY_EXPENSES = "monthly_expenses",
  MORTGAGE_BALANCE = "mortgage_balance",
  CREDIT_SCORE = "credit_score",
  ANNUAL_INCOME = "annual_income",
  PROPERTY_TYPE = "property_type",
  OCCUPANCY_TYPE = "occupancy_type",
  STATE = "state",
  COUNTY = "county",
  PROPERTY_VALUE = "property_value",
  CONFIRM_USER_DETAILS = "confirm_user_details",
  EMAIL = "email",
}

export const CASH_OUT_STEPS_COUNT: number = Object.keys(CASH_OUT_STEPS).length;

/**
 * Modules IDs to use in the DeepLinking feature
 */
export const enum DEEP_LINKING_IDS {
  PERSONAL_GOALS_MODULE_ID = "personal-goals-module",
  PERSONALIZED_OFFERS_MODULE_ID = "personalized-offers-module",
  GENERIC_PERSONALIZED_OFFERS_MODULE_ID = "generic_personalized-offers-module",
}

export const DEEP_LINKING_CURRENT_HASH_ID = "deep_linking_current_hash_id";

export const TERMS_CONDITIONS_OPEN_QUERY_PARAM_NAME = "TCModal";

/**
 * Digital Account Creation
 */
export const DAC_MIXPANEL_MODULE = "digital-account-creation";
export enum DAC_MODULES {
  LOADING = "loading",
  LINK_EXPIRED = "link_expired",
  SESSION_EXPIRED = "session_expired",
  MORTGAGE_SEARCH_FORM = "mortgage_search_form",
  LOAN_SELECTION = "loan_selection",
  TERMS_CONDITIONS = "terms_conditions",
  SET_PASSWORD = "set_password",
  VERIFY_EMAIL = "verify_email",
}

export enum ACCOUNT_TYPES {
  FULL_ACCOUNT = "FULL_ACCOUNT",
  DIGITAL_ACCOUNT = "DIGITAL_ACCOUNT",
}

export enum ACCOUNT_ORIGIN {
  HELOAN = "HELOAN",
}

export enum USER_TAGS {
  FIRST_LOGIN_COMPLETED = "FIRST_LOGIN_COMPLETED",
  HELOAN_INITIALIZED_USER = "HELOAN_INITIALIZED_USER",
}

export enum USER_DISCLAIMERS {
  TERMS_AND_CONDITIONS = "TERMS_AND_CONDITIONS",
  USE_ELECTRONIC_SIGNATURES = "USE_ELECTRONIC_SIGNATURES",
  OBTAIN_CREDIT_REPORTS = "OBTAIN_CREDIT_REPORTS",
  DIGITAL_ACCOUNT_TERMS_AND_CONDITIONS = "DIGITAL_ACCOUNT_TERMS_AND_CONDITIONS",
  DIGITAL_ACCOUNT_USE_ELECTRONIC_RECORDS = "DIGITAL_ACCOUNT_USE_ELECTRONIC_RECORDS",
  DIGITAL_ACCOUNT_TELEPHONE_COMMUNICATIONS = "DIGITAL_ACCOUNT_TELEPHONE_COMMUNICATIONS",
}

/**
 * Home Renovation Calculator
 */
export const KUKUN_IFRAME_RESIZER_LICENSE = "GPLv3";
export const KUKUN_IFRAME_NAME = "home-renovation-calculator-iframe";
export const KUKUN_ORIGIN = "https://qas-services-bayview.mykukun.com";
export const KUKUN_URL = KUKUN_ORIGIN + "/src/estimator.php";
export enum KUKUN_MESSAGES {
  START = "START",
  CONFIRM_START = "CONFIRM_START",
  LOAD_ADDRESS = "LOAD_ADDRESS",
}

export enum LAKEVIEW_PORTAL_URLS {
  LAKEVIEW_FASTLANE_LOAN_SERVICE = "https://lakeviewloanservicing.fastlanefi.com",
  APPLY_NOW = "https://apply.lakeview.com",
}

export const PDP_BUTTONS_MIXPANEL_PARAMS = {
  [pages.products.cashOutRefinance]: {
    path: pages.products.cashOutRefinance,
    product: "cashout",
  },
  [pages.products.getAMortgage]: {
    path: pages.products.getAMortgage,
    product: "mortgage",
  },
  [pages.products.rateTermRefinance]: {
    path: pages.products.rateTermRefinance,
    product: "rate-and-term",
  },
  [pages.products.homeEquityLoan]: {
    path: pages.products.homeEquityLoan,
    product: "heloan",
  },
};

export const PERSONALIZED_OFFERS_MIXPANEL_MODULE = "personalized-offer";
export const PERSONALIZED_OFFERS_POPUP_MIXPANEL_MODULE =
  "personalized-offer-popup";

export const enum SECTIONS_IDS {
  GENERIC_PERSONALIZED_OFFERS_POPUP_DISCLAIMER_ID = "generic-personalized-offers-popup-disclaimer",
}

export const SSO_FIRST_TIME_BANNER = "sso_first_time_banner";

export const HELOAN_INELIGIBLE_STATES = [
  "HI",
  "IL",
  "IN",
  "IA",
  "MN",
  "NY",
  "SC",
  "TX",
  "VT",
  "WA",
];

export enum MODALS_IDS {
  PAY_MORTGAGE_MODAL = "PAY_MORTGAGE_MODAL",
}

export enum API_ERROR_TYPES {
  INVALID_NONCE = "InvalidNonce",
}
export const LAKEVIEW_VIDEO_URL = "https://www.youtube.com/watch?v=zNPrW80vsso";

export const ABOUT_LAKEVIEW_MODULE = "about-lakeview";
export const ABOUT_LAKEVIEW_VIDEO_MODAL = "about-lakeview-video";

export const LKV_URL = "https://www.lkvrealty.com";
/**
 * Button name used for the click events of the
 * Lakeview Home Rewards Program buttons
 */
export const LKV_HOME_REWARDS_EVENT_BUTTON = "Lakeview Home Rewards Program";
export const LKV_PROGRAM_TERMS_EVENT_BUTTON = "Program terms";
