import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { pages } from "../helpers/pages";
import { AppDispatch, RootState } from "../stores";
import { useCallback, useEffect, useRef } from "react";
import {
  SSO_FIRST_TIME_BANNER,
  TERMS_CONDITIONS_OPEN_QUERY_PARAM_NAME,
} from "../helpers/constants";
import { updateTCModal } from "../stores/sso-user-slice";
import { useIsUserAuthenticated } from "./use-is-user-authenticated";

const SSO_BANNER_DISMISSED = "sso_banner_dismissed";

export const useSSOValues = () => {
  const { pathname } = useLocation();
  const [, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();

  const { user } = useSelector((state: RootState) => state.user);
  const confirmedPasswordRef = useRef("");
  const isUserAuthenticated = useIsUserAuthenticated();

  /**
   * Indicates if the user has a pending fastlane account
   */
  const isPendingFastlaneAccount =
    !!user && !!user.did_sso && !user.has_fastlane_account;

  /**
   * Indicates if the user dismissed the first time banner
   */
  const hasDismissedFirstTimeBanner =
    !!user && !!localStorage.getItem(`${user.id}_${SSO_BANNER_DISMISSED}`);

  /**
   * Indicates if the first-time banner can be displayed
   *
   * We can show the first-time banner if:
   * * User did not finish setting up their fastlane account
   * * Did not dismiss the banner in the device
   * * Is in the home page
   */
  const canShowSSOFirstTimeBanner =
    isPendingFastlaneAccount &&
    !hasDismissedFirstTimeBanner &&
    pathname === pages.home;

  /** Store in sessionStorage the value of canShowSSOFirstTimeBanner */
  useEffect(() => {
    const currentValue = sessionStorage.getItem(SSO_FIRST_TIME_BANNER);
    if (!currentValue && isUserAuthenticated) {
      sessionStorage.setItem(
        SSO_FIRST_TIME_BANNER,
        String(canShowSSOFirstTimeBanner)
      );
    }
  }, [canShowSSOFirstTimeBanner, isUserAuthenticated]);

  /** Dismisses the first time banner and saves the state in localstorage */
  const dismissFirstTimeBanner = useCallback(
    () => localStorage.setItem(`${user?.id}_${SSO_BANNER_DISMISSED}`, "true"),
    [user?.id]
  );

  const openTermsModal = (password: string) => {
    /**
     *  We set the password in this way to prevent the modal from opening when
     *  the user refreshes the page with the 'modal=true' parameter and has only typed one character.
     */
    confirmedPasswordRef.current = password;
    setSearchParams({ [TERMS_CONDITIONS_OPEN_QUERY_PARAM_NAME]: "true" });
    dispatch(updateTCModal(true));
  };

  return {
    canShowSSOFirstTimeBanner,
    isPendingFastlaneAccount,
    hasDismissedFirstTimeBanner,
    dismissFirstTimeBanner,
    openTermsModal,
    confirmedPassword: confirmedPasswordRef.current,
  };
};
