import { v4 } from "uuid";
import { lazy } from "react";
import {
  CASH_OUT_BASE_URL,
  DIGITAL_ACCOUNT_VERSIONS,
  TERMS_CONDITIONS_VERSION,
} from "./constants";
import { US_STATES } from "../constants/us-states";
import { User } from "../types";
import { Params } from "react-router-dom";

/**
 *   This functions takes an array and an index,
 *  and returns the accumulative sum of elements
 *  up to the specified index
 * @param {number[]} array - The array of numbers
 * @param {number} index - The index up to which the accumulative sum will be calculated
 * @return {number} The accumulative sum of elements up to the specified index
 */
export const accumulativeSum = (array: number[], index: number): number =>
  array.slice(0, index + 1).reduce((acc, number) => acc + number, 0);

/**
 * Generates an id using uuid v4 for use in keys and other identification methods
 */
export const generateId = (): string => {
  return v4();
};

/**
 * Converts a string input into a valid kebab-case HTML ID format
 */
export const convertToHtmlId = (input: string) => {
  const invalidChars = /[\s]+|[^\w]/g;
  return input.replace(invalidChars, "-").toLowerCase();
};

/**
 * Sorts an array of objects in descending order based on the 'createdAt' property.
 * The 'createdAt' property is expected to be a string that can be converted to a Date object.
 * This function is designed to be used as a comparator in the Array.prototype.sort() method.
 *
 * @param {T} a - The first object to compare, where T is a generic type extending an object with a 'createdAt' property.
 * @param {T} b - The second object to compare, where T is a generic type extending an object with a 'createdAt' property.
 * @return {number} A negative value if 'b' is created later than 'a', a positive value if 'a' is created later than 'b', and 0 if they are created at the same time.
 *
 * Usage example:
 *   const sortedArray = myArray.sort(sortByCreatedAt);
 */
export const sortByCreatedAt = <T extends { createdAt: string }>(
  a: T,
  b: T
): number => {
  const dateA = new Date(a.createdAt).getTime();
  const dateB = new Date(b.createdAt).getTime();
  return dateB - dateA; // Sort in descending order
};

/**
 * Returns if the property of the user is in a state where the LHIS Ad cannot be displayed
 *
 * @param state the state of the user property
 */
export const isUnmarketableState = (state: string | undefined) => {
  if (!state) {
    return false;
  }

  const unmarketableStates = ["CA", "NY", "MA", "GU", "VI", "PR", "AK", "NV"];
  return unmarketableStates.includes(state);
};

/**
 * Function to reload the page if there is a missing js chunk, to avoid out of date code or errors in the application
 */
export const lazyWithRefresh = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  module: () => Promise<any>,
  moduleName: string
) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      sessionStorage.getItem(`dashboard-${moduleName}-is-refreshed`) || "false"
    );

    try {
      const component = await module();
      sessionStorage.setItem(`dashboard-${moduleName}-is-refreshed`, "false");

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        sessionStorage.setItem(`dashboard-${moduleName}-is-refreshed`, "true");

        return location.reload();
      }

      // If the reload didn't help, we throw the error.
      // At this step we can also add additional functionality for better UX
      throw error;
    }
  });

/**
 * Function to validate if the major version number is different to show the T&C / Afba modal
 */
export const isMajorVersionDifferent = (
  documentVersion: string,
  userVersion: string | undefined | null
) => {
  const termsMajorVersion = documentVersion.split(".")[0] ?? "0";
  const userMajorVersion = userVersion ? userVersion?.split(".")[0] : "0";

  return parseInt(termsMajorVersion) > parseInt(userMajorVersion);
};

/**
 * This function is designed to work in conjunction with isMajorVersionDifferent
 * It decides if this is a digital account, or a full account, and
 * return the version of the terms accepted by the user, and the
 * latest version of terms released by Compliance
 *
 * Business rules:
 * * non-SSO login: the borrower will have A type of T&Cs accepted
 * * SSO login: it's possible that no terms are accepted. In that case,
 *        we just show the preview dashboard
 *
 * @param {User | undefined} user
 * @returns {Object} { userTermsVersion, latestTermsVersion }
 */
export const getTermsVersions = (
  user: User | undefined
): { userTermsVersion: string | undefined; latestTermsVersion: string } => {
  const hasUser = !user || !isObjectEmpty(user);

  const hasAnyTermsAccepted =
    !!user?.disclaimer_acceptances?.DIGITAL_ACCOUNT_TERMS_AND_CONDITIONS
      ?.version || !!user?.disclaimer_acceptances?.TERMS_AND_CONDITIONS;

  if (!hasUser || !hasAnyTermsAccepted) {
    return {
      userTermsVersion: "",
      latestTermsVersion: "",
    };
  }

  const isDigitalAccount =
    !!user.disclaimer_acceptances?.DIGITAL_ACCOUNT_TERMS_AND_CONDITIONS
      ?.version;

  const userTermsVersion = isDigitalAccount
    ? user.disclaimer_acceptances?.DIGITAL_ACCOUNT_TERMS_AND_CONDITIONS?.version
    : user.disclaimer_acceptances?.TERMS_AND_CONDITIONS?.version;
  const latestTermsVersion = isDigitalAccount
    ? DIGITAL_ACCOUNT_VERSIONS.TERMS_AND_CONDITIONS
    : TERMS_CONDITIONS_VERSION;

  return {
    userTermsVersion,
    latestTermsVersion,
  };
};

/**
 * Function to get full path for Loan Quote Routes
 */
export const getCashOutFullPath = (path: string) => {
  return `${CASH_OUT_BASE_URL}${path}`;
};

/**
 * Function to convert a currency String previously formatted by formatCurrency to Number
 */
export const formatStringCurrencyToNumber = (value: string) => {
  return +value.replaceAll(/[$.,]/g, "");
};

/**
 * Function to get the state name by value
 */
export const getStateName = (value: string) => {
  const state = US_STATES.filter((state) => state.value === value);
  return state && state.length > 0 ? state[0].title : "";
};

/**
 * Function to check if an object is empty
 */
export const isObjectEmpty = (object: object) => {
  return Object.keys(object).length === 0 && object.constructor === Object;
};

/**
 * The profile page should be hidden only when the borrower is intended
 * to see the digital dashboard
 *
 * @param {User} user
 * @returns {boolean} if the profile page should be shown
 */
export const shouldShowProfilePage = (user: User | undefined) => {
  if (!user || Object.keys(user).length === 0) {
    return false;
  }

  const hasDigitalTerms =
    !!user?.disclaimer_acceptances?.DIGITAL_ACCOUNT_TERMS_AND_CONDITIONS
      ?.version;

  const isDigitalDashboardUser = hasDigitalTerms && !user?.logged_in_with_sso;

  return !isDigitalDashboardUser;
};

export const kebabCase = (inputString: string): string =>
  inputString
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, "-")
    .toLowerCase();

/**
 * Function to get the HQ thumbnail of a youtube video
 */
export const getYoutubeVideoHQThumbnail = (url: string) => {
  const videoId = url.split("v=")[1];
  return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
};

/**
 * Function to round a number to N decimal places
 */
export const roundToNDecimals = (value: number, n: number) =>
  Math.round((value + Number.EPSILON) * Math.pow(10, n)) / Math.pow(10, n);

/**
 * Constructs a URL by appending query parameters to the given base URL.
 *
 * @param {string} url - The base URL to which the query parameters will be appended.
 * @param {Params} params - An object representing the key-value pairs of query parameters to append.
 * @returns {string} The constructed URL including the appended query parameters.
 */
export const buildUrlWithParams = (url: string, params: Params): string => {
  const finalUrl = new URL(url);
  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      finalUrl.searchParams.append(key, String(value));
    }
  });
  return finalUrl.toString();
};
