// Include our external dependencies.
import {
  configureStore,
  isRejected,
  Middleware,
  MiddlewareAPI,
} from "@reduxjs/toolkit";
import { translate as t } from "../helpers/i18n";
import { showToast, toastSlice } from "./toast-slice";
import { bannerSlice } from "./banner-slice";
import { checkForUser, refreshUser, userSlice } from "./user-slice";
import { propertySlice } from "./property-slice";
import { accountSlice, loadAccounts } from "./account-slice";
import { createUserSlice } from "./create-user-slice";
import { emailSlice } from "./email-slice";
import { personalizedOffersSlice } from "./personalized-offers-slice";
import { disclosuresSlice } from "./disclosure-slice";
import { loadMortgage, mortgageSlice } from "./mortgage-slice";
import { appSlice } from "./app-slice";
import { personalGoalsSlice } from "./personal-goals-slice";
import { createSSOUser, SSOUserSlice } from "./sso-user-slice";
import { subservicerSlice } from "./subservicer-slice";
import { tavantSlice } from "./tavant-slice";
import {
  cashOutFlowSlice,
  cashOutLoanSlice,
  cashOutPreQualificationSlice,
  cashOutQuoteSlice,
} from "./cash-out-slice";
import { mortgageSearchSlice } from "./mortgage-search-slice";
import { insuranceApplicationSlice } from "./insurance-application-slice";
import {
  DACSignupInfoSlice,
  DACUserFlowSlice,
} from "./digital-account-creation-slice";
import { nextStepsSlice } from "./next-steps-slice";
import { modalSlice } from "./modal-slice";
import { lakeviewVideoSlice } from "./lakeview-video-slice";

/**
 * This list contains rejected actions that are already handled in a custom way
 * This custom way of handling could be redirecting to an error page or displaying the error
 * somewhere that's not in a toast.
 * For this reason, the actions in this list do not need the catch-all toast
 * that the rtkErrorLogger method throws and need to be skipped.
 */
const ALREADY_HANDLED_REJECTED_ACTIONS = [
  checkForUser.rejected.type, // user check, redirects to error page when rejected
  refreshUser.rejected.type, // refresh user, logs out if the refresh endpoint is unauthorized
  loadMortgage.rejected.type, // mortgage check, displays custom banner when rejected
  createSSOUser.rejected.type, // create SSO user, displays an error message in terms and conditions modal when rejected
  loadAccounts.rejected.type, // Load account, we show a custom error page in the mortgage module
];

const rtkErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (
      isRejected(action) &&
      !ALREADY_HANDLED_REJECTED_ACTIONS.includes(action.type)
    ) {
      if (action?.payload?.showToast !== false) {
        api.dispatch(
          showToast({
            content: action?.error?.message || t("error.server_error"),
            status: "error",
          })
        );
      }
    }

    return next(action);
  };

// Instantiate and export our global store w/ logical slices
const reduxStore = configureStore({
  reducer: {
    app: appSlice.reducer,
    toast: toastSlice.reducer,
    banner: bannerSlice.reducer,
    user: userSlice.reducer,
    createUser: createUserSlice.reducer,
    property: propertySlice.reducer,
    account: accountSlice.reducer,
    email: emailSlice.reducer,
    mortgage: mortgageSlice.reducer,
    personalizeOffers: personalizedOffersSlice.reducer,
    disclosures: disclosuresSlice.reducer,
    personalGoals: personalGoalsSlice.reducer,
    ssoUser: SSOUserSlice.reducer,
    subservicer: subservicerSlice.reducer,
    tavant: tavantSlice.reducer,
    cashOutQuote: cashOutQuoteSlice.reducer,
    cashOutFlow: cashOutFlowSlice.reducer,
    cashOutLoan: cashOutLoanSlice.reducer,
    cashOutPreQualification: cashOutPreQualificationSlice.reducer,
    mortgageSearch: mortgageSearchSlice.reducer,
    insuranceApplication: insuranceApplicationSlice.reducer,
    DACSignupInfo: DACSignupInfoSlice.reducer,
    DACUserFlow: DACUserFlowSlice.reducer,
    nextSteps: nextStepsSlice.reducer,
    modalState: modalSlice.reducer,
    lakeviewVideoState: lakeviewVideoSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(rtkErrorLogger),
});

export type RootState = ReturnType<typeof reduxStore.getState>;
export type AppDispatch = typeof reduxStore.dispatch;

export const dispatch = reduxStore.dispatch;

export default reduxStore;
