import { translate as t } from "../../../helpers/i18n";
import AfBAContent from "../afba/afba-content";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import { useEffect } from "react";
import GenericBaseModal from "../modal/generic-base-modal";
import ModalButtonsWrapper from "../modal/components/modal-buttons-wrapper";
import { Button } from "../../atoms/button";
import { FEATURE_GATES, useFeatureGate } from "../../../hooks/use-feature-gate";
import { Trans } from "react-i18next";
import { ABADModalType } from "../abad-dialog";
import { Link } from "react-router-dom";
import { LakeviewEventParams } from "../../../components/confirm-leave-modal";
import { kebabCase } from "../../../helpers/utils";

type ABADModality = "basic" | ABADModalType;
export interface AcceptAfBAModalProps {
  show: boolean;
  onCancel?: () => void;
  onAccept?: () => void;
  isLoading?: boolean;
  modality?: ABADModality;
  shouldClearModalState?: boolean;
  eventParams?: LakeviewEventParams;
}

const AcceptAfBAModal = ({
  show,
  onCancel,
  onAccept,
  isLoading,
  shouldClearModalState,
  modality = "basic",
  eventParams = {},
}: AcceptAfBAModalProps) => {
  const isABADModeEnabled = useFeatureGate(FEATURE_GATES.ENABLE_ABAD_UPDATE);
  const moduleName = isABADModeEnabled ? "abad-popup" : "afba-conditions";

  const styleABADMode = isABADModeEnabled
    ? {
        dialogClassName: "container px-0 mx-auto d-flex justify-content-center",
        contentClassName:
          "generic-modal-content d-flex flex-column gap-4 p-s16 p-md-s24 col-md-10",
        bodyClassName: "col-12",
      }
    : {};

  const disclosurePDFPath = "/assets/afba/Afba_Disclosure.pdf";

  const handleDisclosureClick = () => {
    const updateEventParams = {
      ...eventParams,
      link: `${process.env.REACT_APP_DOMAIN}${disclosurePDFPath}`,
    };

    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      ...updateEventParams,
      module: moduleName,
      button: "disclosure-pdf",
    });
  };

  const cancelButtonText =
    isABADModeEnabled &&
    (modality === "lhis_insurance_ad_abad" || modality === "lkv_realty_abad")
      ? t("afba.content.cancel")
      : t("afba_modal.cancel_button");

  const renderABADExtraContent = () => {
    if (!isABADModeEnabled) return null;
    switch (modality) {
      case "lhis_insurance_ad_abad":
        return (
          <p className="pt-3">
            <Trans
              i18nKey="afba.content.acknowledgement_insurance"
              components={{
                b: <b />,
              }}
            />
          </p>
        );
      case "lkv_realty_abad":
        return (
          <p className="pt-3">
            <Trans
              i18nKey="afba.content.acknowledgement_lkv_realty"
              components={{
                b: <b />,
              }}
            />
          </p>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (show) {
      const button =
        eventParams?.view_source === "application-lifecycle" ||
        eventParams?.view_source === "personal-goals"
          ? {
              button: eventParams?.button,
            }
          : {};
      const step = eventParams?.step ? { step: eventParams?.step } : {};
      mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
        module: moduleName,
        path: eventParams?.path,
        view_source: eventParams?.view_source,
        ...button,
        ...step,
      });
    }
  }, [show, eventParams, moduleName]);

  const handleOnAccept = () => {
    if (typeof onAccept === "function") {
      mixpanel.track(MIXPANEL_EVENTS.CLICK, {
        ...eventParams,
        module: moduleName,
        button: "agree",
      });
      onAccept();
    }
  };

  const handleCloseClick = () => {
    if (typeof onCancel === "function") {
      const buttonName = isABADModeEnabled ? "close" : "maybe later";
      mixpanel.track(MIXPANEL_EVENTS.CLICK, {
        ...eventParams,
        module: moduleName,
        button: buttonName,
      });
      onCancel();
    }
  };

  const handleCancelClick = () => {
    if (typeof onCancel === "function") {
      mixpanel.track(MIXPANEL_EVENTS.CLICK, {
        ...eventParams,
        module: moduleName,
        button: kebabCase(cancelButtonText),
      });
      onCancel();
    }
  };
  return (
    <GenericBaseModal
      show={show}
      title={
        <>
          <span className="d-md-none">{t("afba_modal.mobile_title")}</span>
          <span className="d-none d-md-block">
            {t("afba_modal.desktop_title")}
          </span>
        </>
      }
      onClose={handleCloseClick}
      onCloseButtonClick={handleCloseClick}
      fullscreen="md-down"
      isLoading={isLoading}
      buttons={
        <>
          <div className="pb-4">
            <Trans
              i18nKey="afba.content.acknowledgement"
              components={{
                b: <b />,
                br: <br />,
                pdf: (
                  <Link
                    to={disclosurePDFPath}
                    onClick={handleDisclosureClick}
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
              }}
            />
            {renderABADExtraContent()}
          </div>
          <ModalButtonsWrapper>
            <Button
              variant="primary"
              onClick={handleOnAccept}
              isLoading={!!isLoading}
            >
              {t("afba_modal.agree_button")}
            </Button>
            <Button variant="secondary" onClick={handleCancelClick}>
              {cancelButtonText}
            </Button>
          </ModalButtonsWrapper>
        </>
      }
      shouldClearModalState={shouldClearModalState}
      {...styleABADMode}
    >
      <>
        <p className="mb-3">{t("afba_modal.content")}</p>
        <AfBAContent />
      </>
    </GenericBaseModal>
  );
};

export default AcceptAfBAModal;
