import { Suspense, useEffect } from "react";
import {
  getTermsVersions,
  isMajorVersionDifferent,
  lazyWithRefresh,
} from "../../../helpers/utils";
import { useSelector } from "react-redux";
import { dispatch, RootState } from "../../../stores";
import { useSSOValues } from "../../../hooks/use-sso-values";
import { updateUIState } from "../../../stores/app-slice";

const TermsAndConditionsModal = lazyWithRefresh(
  () => import("../../../atomic/organisms/terms-and-conditions-modal"),
  "terms-and-conditions-modal"
);

const InitialTermsConditions = () => {
  const { user } = useSelector((state: RootState) => state.user);
  const { showSSOBanner } = useSelector((state: RootState) => state.ssoUser);
  const { showTavantBanner } = useSelector((state: RootState) => state.tavant);
  const { isPendingFastlaneAccount } = useSSOValues();
  const { userTermsVersion, latestTermsVersion } = getTermsVersions(user);

  const showTermsAndConditionsModal =
    !isPendingFastlaneAccount &&
    user &&
    isMajorVersionDifferent(latestTermsVersion, userTermsVersion);

  useEffect(() => {
    dispatch(
      updateUIState({
        isContentBlockOverlay:
          showSSOBanner || showTermsAndConditionsModal || showTavantBanner,
      })
    );
  }, [showSSOBanner, showTavantBanner, showTermsAndConditionsModal]);

  return (
    showTermsAndConditionsModal && (
      <Suspense fallback={null}>
        <TermsAndConditionsModal isUpdatedTermsAndConditions />
      </Suspense>
    )
  );
};

export default InitialTermsConditions;
